import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { faTrashAlt, faUser } from "@fortawesome/free-regular-svg-icons";
import CustomPagination from "../../common/Pagination/CustomPagination";
import {
  actionIcons,
  customPagination,
  userContainer,
} from "./UserManagement.module.scss";
import ReactToolTip from "../../common/ReactToolTip/ReactToolTip";
import useMobileDetect from "../../../hooks/useMobileDetect";
import GlobalFilters from "../../common/GlobalFilters/GlobalFilters";
import { Button, Col, Row } from "react-bootstrap";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import UserDelete from "./UserDelete";
import NewUserProfileModal from "./NewUserProfileModal";
import { IUserManagement, IUserProfile } from "./UserManagementProps";
import OverlaySpinner from "../../common/OverlaySpinner/OverlaySpinner";
import EditUserProfileModal from "./EditUserProfileModal";
import {
  handleSelectUser,
  setEditUserModal,
  setSearchValue,
  setUserModal,
  handleDeleteSelectedUser,
  setPagination,
  setUserList,
} from "../../../redux/slices/HotelUserManagement/HotelUserManagement";
import { getHotelUserList } from "../../../redux/slices/HotelUserManagement/Action";
import useQueryParams from "../../../hooks/useQueryParams";
import { IHotel } from "../../../@types/IHotel";
import { useAppSelector } from "../../../hooks/redux";
import { hotelSelector } from "../../../redux/slices/Profile/ProfileSelectors";

const roleList = [
  { label: "Please select a role", value: "" },
  { label: "Management Company", value: "Management Company" },
  { label: "Assistant General Manager", value: "Assistant General Manager" },
  { label: "Director of Sales", value: "Director of Sales" },
  { label: "Sales Manager", value: "Sales Manager" },
  { label: "Accounting Manager", value: "Accounting Manager" },
  { label: "Front Office Manager", value: "Front Office Manager" },
  { label: "Housekeeping Manager", value: "Housekeeping Manager" },
  { label: "Chief Engineer", value: "Chief Engineer" },
  { label: "Food & Beverage Manager", value: "Food & Beverage Manager" },
  { label: "Hotel Manager", value: "Hotel Manager" },
  { label: "Hotel Employee", value: "Hotel Employee" },
  { label: "Third Party Service", value: "Third Party Service" },
  {
    label: "Area Director of Operations",
    value: "Area Director of Operations",
  },
  { label: "General Manager", value: "General Manager" },
  // { label: "Primary Owner", value: "Primary Owner" },
  // { label: "Secondary Owner", value: "Secondary Owner" },
  // { label: "Previous Owner", value: "Previous Owner" },
  // { label: "Other", value: "Other" },
  // { label: "Property", value: "Property" },
];

export const UserManagementHelpers = () => {
  const dispatch = useDispatch();
  const initialValues = {
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    personalEmail: "",
  };
  const hotelUserManagement = useSelector(
    (state: { hotelUserManagement: IUserManagement }) =>
      state.hotelUserManagement
  );
  const { pagination, userList, isLoading } = hotelUserManagement;
  const queryParams = useQueryParams();
  const hotelId = queryParams.get("hotelId");
  const currentUser: IHotel = useAppSelector(hotelSelector(hotelId));
  useEffect(() => {
    dispatch<any>(getHotelUserList(currentUser?.id));
  }, []);

  const existedUserRoles = [...new Set(userList?.map(item => item.role))];

  const onHideUserModal = () => {
    dispatch(handleSelectUser(initialValues));
    dispatch(setEditUserModal(false));
    dispatch(setUserModal(false));
    dispatch(setSearchValue(""));
  };
  const onHideUserDeleteModal = () => {
    dispatch(handleDeleteSelectedUser(false));
    dispatch(handleSelectUser(initialValues));
  };
  const handleDeleteConfirm = () => {
    // eslint-disable-next-line no-console
    console.log("selectedUser", hotelUserManagement.selectedUser);
  };
  const onClearSearch = () => {
    dispatch(setSearchValue(""));
  };
  const onChangeSearch = (e: { target: { value: string } }) => {
    dispatch(setSearchValue(e.target.value));
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "firstName",
      text: "First Name",
    },
    {
      dataField: "lastName",
      text: "Last Name",
    },
    {
      dataField: "role",
      text: "Role",
    },
    {
      dataField: "personalEmail",
      text: "E-Mail",
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (_col: any, row: IUserProfile) => (
        <div className={actionIcons}>
          <UserAction rowData={row} />
        </div>
      ),
    },
  ];
  const mobileColumns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "personalEmail",
      text: "E-Mail",
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (_col: any, row: IUserProfile) => (
        <div className={actionIcons}>
          <UserAction rowData={row} />
        </div>
      ),
    },
  ];

  const paginationMessage = () =>
    `Showing ${pagination.offset === 0 ? 1 : pagination.offset} to ${
      userList && userList.length < pagination.endOffSet
        ? userList && userList.length
        : pagination.endOffSet
    } of ${userList && userList.length} plan items.`;

  const handlePagination = (data: { selected: number }) => {
    dispatch(
      setPagination({
        ...pagination,
        currentPage: data.selected + 1,
        offset: data.selected * pagination.limit,
        endOffSet: (data.selected + 1) * pagination.limit,
      })
    );
  };

  useEffect(() => {
    filterByValue(
      hotelUserManagement.hotelUserList,
      hotelUserManagement.searchValue
    );
  }, [hotelUserManagement.searchValue]);
  const filterByValue = (arrayOfObject: Array<IUserProfile>, term: string) => {
    const filterUserList: Array<IUserProfile> = arrayOfObject.filter(function (
      v
    ) {
      if (
        v.firstName?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        v.lastName?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        v.role?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        v.personalEmail?.toLowerCase().indexOf(term.toLowerCase()) > -1
      ) {
        return true;
      } else {
        return false;
      }
    });
    dispatch(
      setPagination({
        ...pagination,
        totalCount: filterUserList.length,
        numPages: Math.ceil(filterUserList.length / 10),
        currentPage: 1,
        offset: 0,
        endOffSet: 10,
        limit: 10,
      })
    );
    dispatch(setUserList(filterUserList));
  };

  const isMobileOnly = useMobileDetect();
  return (
    <div className={`${userContainer} mt-4`}>
      {isLoading ? <OverlaySpinner /> : null}
      <Row className="mb-4">
        <Col lg={4} md={4} xs={9}>
          <GlobalFilters
            onChangeSearch={onChangeSearch}
            value={hotelUserManagement.searchValue}
            onClearSearch={onClearSearch}
          />
        </Col>
        <Col lg={2} md={2} className="d-none d-sm-block d-md-block"></Col>
        <Col lg={6} md={6} xs={3} className="d-flex flex-row-reverse ">
          <Button
            className="btn new-user-btn"
            onClick={() => {
              dispatch(setUserModal(true));
            }}
          >
            <FontAwesomeIcon icon={faUserPlus} />
            {isMobileOnly ? null : ` New User`}
          </Button>
        </Col>
      </Row>
      <div className="row">
        <BootstrapTable
          keyField="PipsId"
          data={userList?.slice(pagination.offset, pagination.endOffSet) ?? []}
          columns={isMobileOnly ? mobileColumns : columns}
          bordered={false}
          rowClasses="custom-table-class"
        />
        {userList && userList.length ? (
          <div className={`col-12 ${customPagination}`}>
            <div className="row footer-wrapper" style={{ margin: "auto" }}>
              <div className="col-md-6">
                <span>{paginationMessage()}</span>
              </div>
              <div className="col-md-6">
                <div className="d-flex customPag">
                  <CustomPagination
                    numPages={pagination.numPages}
                    itemsPerPage={pagination.limit}
                    currentPage={pagination.currentPage}
                    handlePageClick={handlePagination}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {hotelUserManagement.userModal ? (
        <NewUserProfileModal
          isShow={hotelUserManagement.userModal}
          onHide={onHideUserModal}
          roleList={roleList}
          existedUserRoles={existedUserRoles}
        />
      ) : null}
      {hotelUserManagement.editUserModal ? (
        <EditUserProfileModal
          isShow={hotelUserManagement.editUserModal}
          onHide={onHideUserModal}
          selectedUser={hotelUserManagement.selectedUser}
          existedUserRoles={existedUserRoles}
        />
      ) : null}
      {hotelUserManagement.userDeleteModal ? (
        <UserDelete
          userDeleteModal={hotelUserManagement.userDeleteModal}
          onHide={onHideUserDeleteModal}
          handleDeleteConfirm={handleDeleteConfirm}
          isShow={hotelUserManagement.userDeleteModal}
        />
      ) : null}
    </div>
  );
};

export const UserAction = (props: { rowData: IUserProfile }) => {
  const queryParams = useQueryParams();
  const hotelId = queryParams.get("hotelId");
  const currentUser: IHotel = useAppSelector(hotelSelector(hotelId));
  const userRole = currentUser.connectionTypeName;
  const owners = ["Primary Owner", "Secondary Owner"];
  const gm = ["General Manager"];
  const OtherManagers = [
    "Assistant General Manager",
    "Front Office Manager",
    "Food & Beverage Manager",
    "Accounting Manager",
    "Sales Manager",
    "Housekeeping Manager",
    "Director of Sales",
    "Chief Engineer",
    "Hotel Manager",
  ];
  const others = [
    "Hotel Employee",
    "Property",
    "Other",
    "Management Company",
    "Area Director of Operations",
    "Third Party Service",
  ];
  if (owners.includes(userRole)) {
    if (owners.includes(props.rowData.role)) {
      return (
        <>
          <EditAction row={props.rowData} />
        </>
      );
    } else {
      return (
        <>
          <EditAction row={props.rowData} />
          <DeleteAction row={props.rowData} />
        </>
      );
    }
  }
  if (gm.includes(userRole)) {
    if (!owners.includes(props.rowData.role)) {
      if (
        OtherManagers.includes(props.rowData.role) ||
        others.includes(props.rowData.role)
      ) {
        return (
          <>
            <EditAction row={props.rowData} />
            <DeleteAction row={props.rowData} />
          </>
        );
      } else {
        return <EditAction row={props.rowData} />;
      }
    }
  }
  if (OtherManagers.includes(userRole)) {
    if (
      !owners.includes(props.rowData.role) &&
      !gm.includes(props.rowData.role)
    ) {
      if (others.includes(props.rowData.role)) {
        return (
          <>
            <EditAction row={props.rowData} />
            <DeleteAction row={props.rowData} />
          </>
        );
      } else {
        return (
          <>
            <EditAction row={props.rowData} />{" "}
          </>
        );
      }
    }
  }
  if (others.includes(userRole)) {
    if (
      !owners.includes(props.rowData.role) &&
      !gm.includes(props.rowData.role)
    ) {
      if (!OtherManagers.includes(props.rowData.role)) {
        return <EditAction row={props.rowData} />;
      }
    }
  }

  return null;
};

export const EditAction = (props: any) => {
  const dispatch = useDispatch();
  return (
    <span
      className="userIcon"
      onClick={() => {
        dispatch(handleSelectUser(props.row));
        dispatch(setEditUserModal(true));
      }}
    >
      <ReactToolTip
        theme="dark"
        position="top"
        title="Profile"
        containerClass="tooltipStyleChange"
      >
        <FontAwesomeIcon icon={faUser} className="mt-1" />
      </ReactToolTip>
    </span>
  );
};

export const DeleteAction = (props: any) => {
  const dispatch = useDispatch();
  return (
    <span
      className="trashIcon"
      onClick={() => {
        dispatch(handleDeleteSelectedUser(true));
        dispatch(handleSelectUser(props.row));
      }}
    >
      <ReactToolTip
        theme="red"
        position="top"
        title="Remove user"
        containerClass="tooltipStyleChange"
      >
        <FontAwesomeIcon icon={faTrashAlt} className="mt-1" />
      </ReactToolTip>
    </span>
  );
};
